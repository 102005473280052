<template>
  <div id="Menu" class="ctn bg">
      <div class="d-flex justify-content-center text-center mt-3">
        <h2 class="observable title">{{ Title }}</h2>
      </div>   
      <div class="observable caroussel container mb-5">
        <div class="maincont mx-auto">
            <agile fade :speed="1000">
                <div v-bind:key="menu.id" v-for="menu in Menus">
                    <img :src="menu.path">
                </div>
                <template #prevButton><i class="fas fa-chevron-left"></i></template>
                <template #nextButton><i class="fas fa-chevron-right"></i></template>
            </agile>
          </div>
      </div>
        <span class="observable d-sm-none d-block content-button mx-auto mt-3 py-3">
          <i class="fas fa-hand-point-left"></i> Swipe ! <i class="fas fa-hand-point-right"></i>
        </span>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    Menus() {
      return this.$store.getters['Menu/getmenu'];
    },
    Title() {
      return this.$store.getters['Menu/gettitle'];
    },
  },
};
</script>

<style lang="sass">
  .caroussel
    .agile
      &__dots
        bottom: 0px
        flex-direction: row
        right: 27px
        position: absolute
        max-height: 100%
      &__dot
        margin: 30px 0
        button
          background-color: transparent
          border: 2px solid rgba(14, 37, 55, 1)
          cursor: pointer
          display: block
          height: 15px
          font-size: 0
          line-height: 0
          margin: 5px
          padding: 0
          transition-duration: .5s
          width: 15px
        &--current,
        &:hover
          button
            background-color: rgba(14, 37, 55, 1)
      &__nav-button
        background: rgba(14, 37, 55, 0)
        border: none
        color: #fff
        cursor: pointer
        font-size: 50px
        position: absolute
        top: 45%
        transition-duration: .3s
        width: 45px
        &--prev
          left: 0
          margin-left: 1rem
          color: rgba(14, 37, 55, 1)
        &--next
          right: 0
          margin-right: 1rem
          color: rgba(14, 37, 55, 1)
        &:hover
            background-color: rgba(14, 37, 55, 0.4)
@media (max-width: 1199px)
  .galcaroussel
    .agile
      &__nav-button
        &:hover
          background-color: rgba(14, 37, 55, 0)
@media (max-width: 576px)
  .caroussel
   .agile
      &__dots
        display: none
      &__nav-button
        display: none
@media (hover: hover)
  .galcaroussel
    .agile
      &__nav-button
        background-color: rgba(14, 37, 55, 0)
</style>
<style src="./TheMenu.css" scoped></style>