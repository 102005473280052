<template>
    <div id="Galerie" class="gal mx-auto bg">
        <div class="d-flex justify-content-center text-center pt-5">
            <h2 class="observable titleg">{{ titleG[lang] }}</h2>
        </div> 
        <div class="observable galcaroussel container pt-5">
                <div class="maincont mx-aut">
                    <agile class="main" ref="main" :as-nav-for="asNavFor1" autoplay :autoplaySpeed="4500" pauseOnHover 
                    fade :speed="2000" :dots="false" :navButtons="true" :swipe-distance="100">
                        <div class="mainpic" v-bind:key="Gallerie.id" v-for="Gallerie in this.Galleries">
                            <img class="pic" :src="Gallerie.path">
                        </div>
                        <template #prevButton><i class="fas fa-chevron-left"></i></template>
                        <template #nextButton><i class="fas fa-chevron-right"></i></template>
                    </agile>
                <!--<div class="thumbcont mx-auto">
                    <agile class="thumbnails" ref="thumbnails" :as-nav-for="asNavFor2" :slides-to-show="6" :dots="false" :navButtons="false" :centerMode="true">
                        <div class="thumbspic" v-bind:key="Gallerie.id" v-for="Gallerie in this.Galleries" @click="$refs.thumbnails.goTo(Gallerie.id)">
                            <img class="thpic" :src="Gallerie.path">
                        </div>
                    </agile>
                </div>-->
            </div>
        </div>
    </div>
</template>

<script>

export default {
data() {
    return {
      asNavFor1: [],
      asNavFor2: [],
      titleG: {
        fr: 'Galerie',
        en: 'Gallery',
      }
    };
  },
  mounted () {
    this.asNavFor1.push(this.$refs.thumbnails)
    this.asNavFor2.push(this.$refs.main)
  },
  computed: {
    Galleries() {
      return this.$store.state.Gallerie.Gallerie;
    },
    lang() {
      return this.$store.getters['lang/getCurrentLang'];
    },
  },
}
</script>

<style lang="sass">
  .galcaroussel
    .agile
      width: 100%
      min-width: 57rem
      &__nav-button
        background: transparent
        border: none
        color: #fff
        cursor: pointer
        font-size: 40px
        height: 100%
        position: absolute
        top: 0
        transition-duration: .3s
        width: 80px
        &:hover
          background-color: rgba(0, 0, 0, 0.7)
          opacity: 1
        &--prev
          left: 0
        &--next
          right: 0
      .pic
        max-height: 40rem !important
        align-self: center
        justify-self: center
      .mainpic  
        img
          object-fit: cover
          object-position: center
          width: 100%
          border: 2px solid rgba(0, 0, 0, 0.7)
      .thumbspic
        img
          object-fit: cover
          object-position: center
          width: 100%
@media (max-width: 1199px)
  .galcaroussel
    .agile
      &__nav-button
        font-size: 30px
        &:hover
          background-color: rgba(14, 37, 55, 0)
@media (max-width: 996px)
   .galcaroussel
    .agile
      min-width: 27rem
@media (max-width: 576px)
 .galcaroussel
    .agile
      min-width: 20rem
      &__nav-button
        font-size: 30px
        &:hover
          background-color: rgba(14, 37, 55, 0)
@media (max-width: 400px)
  .galcaroussel
    .agile
      min-width: 18rem
@media (hover: hover)
  .galcaroussel
    .agile
      &__nav-button
        background-color: rgba(14, 37, 55, 0)
</style>
<style src="./Gallery.css" scoped></style>