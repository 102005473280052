<template>
    <div id="About" class="ctn-about d-flex justify-content-center align-items-center mx-auto bg">
            <div class="observable text-justify about-apropos p-4 m-5">
                <h1 class="about-title-apropos font-weight-bold mb-4">
                    {{ titleA[lang] }}
                </h1>
                <div v-html="Apropos" class="about-paragprah-apropos"></div>
        </div>
    </div>
</template>

<script>

export default ({
  data() {
    return{
      titleA: {
        fr: 'A propos',
        en: 'About'
      },
    };
  },
  components: {},
  computed: {
    Apropos() {
      return this.$store.getters['Apropos/getapropos'];
    },
    lang() {
      return this.$store.getters['lang/getCurrentLang'];
    },
  },
})
</script>

<style src='./About.css' scoped></style>
