<template>
    <div id="Accueil" class="intro d-flex flex-column justify-content-center align-items-center bg">
        <div class="">
            <div class="observable container-intro">
                <div class="text-center pb-3 pt-4">
                    <h1>Waterside</h1>
                    <h3>café & kitchen</h3>
                </div>
            </div>
            <div class="observable container-txt mt-4 mb-4 pb-1 pt-4 pl-3 pr-3">
                <p>{{ Timetxt }}</p>
            </div>
        </div>
        <div class="observable cb">
            <a href="#Menu" class="ctn-btn">
                <i class="fas fa-coffee mr-3"></i>
                <span>{{ btn[lang] }}</span>     
            </a>
        </div>
    </div>
</template>

<script>

export default ({
    data() {
        return {
            btn: {
                fr: 'Découvrir...',
                en: 'Discover...'
            },
        };
    },
    computed: {
        Timetxt() {
            return this.$store.getters['Home/gettimeTxt'];
        },
        lang() {
            return this.$store.getters['lang/getCurrentLang'];
        },
    },
})
</script>

<style src="./TheIntro.css" scoped></style>