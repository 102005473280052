<template>
    <div class="ctnvue d-flex flex-column justify-content-center align-items-center mx-auto bg" id="Vue">
        <div class="d-flex flex-column flex-sm-row mb-5" id="Vue2"><h2 class="observable title-vue pl-3">{{ titleV[lang] }}</h2></div>
        <div class="d-flex flex-column flex-sm-row" id="Vue2">
            <div class="observable content-container">
                <iframe width='100%' height='100%' src='https://my.matterport.com/show/?m=2FeBhk9Dj5D' frameborder='0' allowfullscreen allow='xr-spatial-tracking'></iframe>
            </div>
        </div>
    </div>
</template>

<script>

export default ({
    data() {
        return{
            titleV: {
                fr: 'Visite 3D',
                en: '3D Tour'
            }
        };
    },
    computed: {
        lang() {
            return this.$store.getters['lang/getCurrentLang'];
        },
    },
})
</script>


<style src='./TheVue.css' scoped></style>
