<template>
    <div class="ctnCLO d-flex flex-lg-row flex-column justify-content-center align-items-center mx-auto bg" id="CLO">
        <div class="col loc-col observable" id="loc-col">
            <strong><p class="titre pl-3">Localisation:</p></strong>
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12929.374842910362!2d10.5947953!3d35.8895968!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x814f68b18794bf1a!2sWaterside%20Caf%C3%A9%20%26%20Kitchen!5e0!3m2!1sfr!2stn!4v1641204686908!5m2!1sfr!2stn" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        </div>
        <div class="loc-col-2 observable">
            <div class="contact-info ml-5">
                <strong><p class="titre">Contact:</p></strong>
                <strong style="cursor: default;"><i class="fas fa-map-marker-alt"></i> {{ address[lang] }}</strong>
                <address>
                    Rue des Palmiers BP 171 4089 <br/>
                    Port El Kantaoui Hammam-Sousse,
                    Tunisie<br/>
                </address>
                <strong style="cursor: default;"><i class="fas fa-phone"></i> {{ phone[lang] }} </strong>
                <p class="d-none d-lg-block">(+216) {{ contact.tel }} </p>
                <p class="d-lg-none"><a class="tel" :href="`tel:${ contact.tel }`">(+216) {{ contact.tel }}</a> </p>
                <strong style="cursor: default;"><i class="far fa-envelope"></i> {{ mail[lang] }}</strong>
                <p class="d-none d-lg-block"> {{ contact.email }} </p>
                <p class="d-lg-none"><a class="mail" :href="`mailto:${ contact.email }`">{{ contact.email }}</a> </p>
            </div>
        </div>
    </div>    
</template>

<script>

export default ({
    data() {
        return {
            address: {
                fr: 'Adresse',
                en: 'Address'
            },
            phone: {
                fr: 'Téléphone',
                en: 'Phone'
            },
            mail: {
                fr: 'Email',
                en: 'Mail'
            }
        }
    },
    computed: {
        contact() {
            return this.$store.state.Apropos.contact;
        },
        lang() {
            return this.$store.getters['lang/getCurrentLang'];
        },
    },
})
</script>
<style src="./TheCLO.css" scoped></style>
