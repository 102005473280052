<template>
  <div>
    <the-intro></the-intro>
    <the-menu></the-menu>
    <TheCLO></TheCLO>
    <the-vue></the-vue>
    <gallery></gallery>
    <about></about>
  </div>
</template>

<script>

import TheIntro from '@/components/TheIntro/TheIntro.vue';
import TheMenu from '@/components/TheMenu/TheMenu.vue';
import TheCLO from '@/components/TheCLO/TheCLO.vue';
import TheVue from '@/components/TheVue/TheVue.vue';
import About from '@/components/About/About.vue';
import Gallery from '@/components/Gallery/Gallery.vue';

export default {
  data() {
    return{};
  },
  components: {
    TheIntro,
    TheMenu,
    TheCLO,
    TheVue,
    About,
    Gallery
  },
  computed: {
    Home() {
      return this.$store.state.Home.home;
    },
    year() {
      const date = new Date();
      const yend = new Date(2024, 0, 1);
    
      if (date <= yend) return true;
      else return false;
    }
  },
  methods: {
    hide() {
      var x1 = document.getElementById("container1");
      var x2 = document.getElementById("container2");
      var x3 = document.getElementById("container3");

      if ((x1.style.display && x2.style.display && x3.style.display)  === "none") {
        x1.style.display = "block";
        x2.style.display = "block";
        x3.style.display = "block";
      } else {
        x1.style.display = "none";
        x2.style.display = "none";
        x3.style.display = "none";
      }
    },
    onScreen() {
      const clos = document.querySelectorAll('.observable');
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible", entry.isIntersecting)
            observer.unobserve(entry.target);
          }
        })
      }, { threshold: 0, rootMargin: "-6%" })
      clos.forEach(clo => {
        observer.observe(clo)
      })
    },
  },
  mounted() {
    this.onScreen()
    if ((navigator.userAgent.indexOf("Mac") && navigator.userAgent.indexOf("Mobile")) != -1) {
      const coms = document.getElementsByClassName('bg')
      for (let i = 0; i < coms.length; i++) {
        coms[i].classList.add('bg-ios')
      }
    }
  }
  /*mounted() {
    var countDownDate = new Date(2024, 0, 1).getTime();
    var x = setInterval( function() {
    var now = new Date().getTime();
    var distance = countDownDate - now;
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);
    
    document.getElementById("counter").innerHTML = days + "d " + hours + "h "
    + minutes + "m " + seconds + "s ";
    
    if (distance < 0) {
      clearInterval(x);
      if (localStorage.getItem('reloaded')) {
        localStorage.removeItem('reloaded');
      } else {
        localStorage.setItem('reloaded', '1');
        location.reload();
      }
    }
    }, 1000);
  }*/
};
</script>

<style src="./Accueil.css" scoped></style>
